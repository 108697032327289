import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import * as compose from 'lodash.flowright';
import { Field } from 'redux-form';
import { DeviceTypeQuery } from '../../graphql/queries/DeviceTypeQueries';
import renderCheckbox from '../form/renderCheckbox';
import Ap2200FirmwareDropdown from '../ap2200/ap2200Firmware/Ap2200FirmwareDropdown';

class DeviceTypeSpecificFields extends Component {
  render() {
    if (!this.props.data) {
      return <div />;
    }
    const {
      data: { loading, error, deviceType },
    } = this.props;

    if (loading) {
      return <div />;
    } if (error) {
      return <p>Error Loading Device Type Specific Settings!</p>;
    }
    return (
      <div>
        {deviceType.key && deviceType.key === 'ap2200' && this.props.auth && this.props.auth.isSuperAdmin
            && (
            <div>
              <Field
                name="sendAp2200LogFile"
                label="Request Log File"
                component={renderCheckbox}
              />
              <Field
                name="forceFirmwareUpdate"
                label="Force Firmware Update"
                component={renderCheckbox}
              />
              <Ap2200FirmwareDropdown fieldName="desiredFirmware" label="Desired AP2200 Firmware" />

            </div>
            )}
      </div>
    );
  }
}

DeviceTypeSpecificFields.defaultProps = {
  formName: 'Device',
};
const mapStateToProps = ({ auth }) => ({ auth });
export default compose(
  connect(mapStateToProps),
  graphql(DeviceTypeQuery, {
    skip: (ownProps) => !ownProps.deviceTypeId,
    options: (ownProps) => ({
      variables: { id: ownProps.deviceTypeId },
    }),
  }),
)(DeviceTypeSpecificFields);
