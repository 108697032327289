import { toastr } from 'react-redux-toastr';
import formatMutationValues from './formatMutationValues';
import SuccessMessage from '../../components/common/SuccessMessage';

export default ({
  mutate,
  onSuccess,
  variables,
  refetchQueries,
  mutation,
  onError,
  dontShowSuccess,
  update,
}) => {
  const vars = formatMutationValues({ variables });
  console.log('handle mutation varsAfter', vars);
  console.log('handle mutation refetchQueries', refetchQueries);
  const options = {
    variables: vars,
    refetchQueries,
    update,
  };
  if (mutation) {
    options.mutation = mutation;
  }

  mutate(options)
    .then((response) => {
      if (dontShowSuccess !== true) {
        SuccessMessage({ msg: '' });
      }
      onSuccess(response);
    })
    .catch((err) => {
      console.error('mutation error', err);
      if (onError) {
        onError(err);
      }
      toastr.light('Error', err.message, {
        icon: 'error',
        status: 'error',
        timeOut: 10000,
      });
    });
};
