import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ChildAssetNew from './childAsset/ChildAssetNew';
import ChildAssetGrid from './childAsset/ChildAssetGrid';

class AssetNewComponents extends Component {
  constructor(props) {
    super(props);
    this.state = { componentAdded: false };
    this.handleAddComponent = this.handleAddComponent.bind(this);
  }

  handleAddComponent() {
    this.setState({ componentAdded: true });
  }

  render() {
    return (
      <div>
        <Container>
          {(() => {
            switch (this.state.componentAdded) {
              case true:
                return (
                  <div>
                    <Typography variant="h4" className="ps-form-header">{`${this.props.createdAsset.name} Components`}</Typography>
                    <ChildAssetGrid
                      parent={this.props.createdAsset}
                    />
                  </div>
                );
              default:
                return (
                  <ChildAssetNew
                    parent={this.props.createdAsset}
                    onSuccess={this.handleAddComponent}
                  />
                );
            }
          })()}

        </Container>

      </div>
    );
  }
}
AssetNewComponents.defaultProps = {
  loading: false,
  error: false,
  header: '',
};
export default AssetNewComponents;
