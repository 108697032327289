import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../form/FormField';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';
import renderCheckbox from '../form/renderCheckbox';
import ModelDashboardDropdown from '../modelDashboard/ModelDashboardDropdown';

class CompanyForm extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          component={FormField}
          label="Company Name"
        />

        <Field name="obfuscateName" type="text" component={FormField} label="Obfuscated Name" />
        <ModelDashboardDropdown
          label="Default Dashboard"
          fieldName="modelDashboard"
        />
        <Field name="isMonitoringCompany" label="Monitoring Company" component={renderCheckbox} />
        <Field name="displayRul" label="Display RUL" component={renderCheckbox} />
        <br />
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  return errors;
};

export default reduxForm({
  validate,
  form: 'Company',
})(CompanyForm);
