import React, { Component } from 'react';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { connect } from 'react-redux';

import ChildAssetForm from './ChildAssetForm';
import FormBase from '../../form/FormBase';
import handleAssetMutation from '../../../graphql/functions/handleAssetMutation';
import {
  AssetQuery,
  ChildAssetsQuery,
} from '../../../graphql/queries/AssetQueries';
import { UpdateAssetMutation } from '../../../graphql/mutations/AssetMutations';
import { CreateAssetTypeMutation } from '../../../graphql/mutations/AssetTypeMutations';

class ChildAssetUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // store asset id
      values.id = this.props.asset.id;

      const refetchQueries = [];
      values.parent = this.props.asset.parentId;
      refetchQueries.push({
        query: ChildAssetsQuery,
        variables: { parentId: this.props.asset.parentId },
      });
      delete values.company;
      delete values.location;
      delete values.components;
      delete values.childAssets;
      delete values.uploadCSV;

      if (this.props.auth && this.props.auth.obfuscate === true) {
        delete values.name;
      }

      handleAssetMutation({
        values,
        assetTypeMutation: this.props.createAssetTypeMutation,
        assetMutation: this.props.updateAssetMutation,
        onSuccess: this.props.onSuccess,
        refetchQueries,
      });
    }
  }

  render() {
    const {
      AssetQuery: { loading, error, asset },
    } = this.props;
    // organize the initial assetTypeConfig to match the order of the assetType fields
    let formattedAsset = asset;
    if (asset && asset.assetType && asset.assetType.fields) {
      const organizedConfig = asset.assetType.fields.map((f) => {
        let configVal;
        const foundValue = asset.assetTypeConfig.find((c) => c !== null && typeof c !== 'undefined' && c.k === f.key);
        if (foundValue) {
          configVal = foundValue;
        } else if (f.rule === 'Boolean') {
          // if this boolean has not been set, automatically set it to false
          configVal = { k: f.key, v: false };
        } else {
          configVal = { k: f.key, v: null };
        }
        return configVal;
      });
      formattedAsset = { ...asset, assetTypeConfig: organizedConfig };
    }
    return (
      <FormBase loading={loading} error={error} header="Edit Asset Component">
        <ChildAssetForm
          initialValues={formattedAsset}
          onSubmit={this.handleSubmit}
        />
      </FormBase>
    );
  }
}

ChildAssetUpdate = compose(
  graphql(UpdateAssetMutation, {
    name: 'updateAssetMutation',
    options: (props) => ({ variables: { id: props.asset.id } }),
  }),
  graphql(CreateAssetTypeMutation, { name: 'createAssetTypeMutation' }),
  graphql(AssetQuery, {
    name: 'AssetQuery',
    options: (props) => ({ variables: { id: props.asset.id }, fetchPolicy: 'network-only' }),
  }),
)(ChildAssetUpdate);

function mapStateToProps({ auth }) {
  return { auth };
}
export default withApollo(connect(mapStateToProps)(ChildAssetUpdate));
