import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import ReactTable from 'react-table';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import ContextMenuWithTrigger from '../../common/ContextMenuWithTrigger';
import { ChildAssetsQuery } from '../../../graphql/queries/AssetQueries';
import { DeviceGridQuery } from '../../../graphql/queries/DeviceQueries';
import { DeleteAssetMutation } from '../../../graphql/mutations/AssetMutations';
import DeleteModel from '../../common/DeleteModel';
import cacheDeleteFromArray from '../../../graphql/functions/cacheDeleteFromArray';
import Modal from '../../common/Modal';
import ChildAssetNew from './ChildAssetNew';
import ChildAssetUpdate from './ChildAssetUpdate';

class ChildAssetGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalLabel: 'Edit Asset Component',
      modalType: 'update',
      asset: null,
      parent: null,
      delete: { id: null, name: '' },
      updateFn: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleAddSubAssetClick = this.handleAddSubAssetClick.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  childColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 100,
      filterable: false,
    },
    {
      Header: 'Company Id',
      id: 'companyId',
      accessor: (d) => (d.parent && d.parent.location && d.parent.location.company
        ? d.parent.location.company.id
        : null),
      show: false,
    },
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 90,
      show: false,
    },
    {
      Header: 'Parent Id',
      id: 'parentId',
      accessor: (d) => d.parent.id,
      show: false,
    },
    {
      Header: 'Type',
      id: 'assetType',
      accessor: (d) => (d.assetType ? d.assetType.name : ''),
      minWidth: 100,
      filterable: false,
    },
    {
      Header: '',
      maxWidth: 23,
      resizable: false,
      sortable: false,
      Cell: ({ row }) => ContextMenuWithTrigger({
        handleClick: this.handleClick,
        row,
      }),
    },
  ];

  addSubAssetButton() {
    return (
      <Button
        className="right"
        onClick={() => {
          this.handleAddSubAssetClick(this.props.parent);
        }}
      >
        Add Component
        {' '}
        <Add />
      </Button>
    );
  }

  handleAddSubAssetClick(data) {
    this.setState({
      parent: data,
      modalIsOpen: true,
      modalLabel: 'Add Asset Component',
      modalType: 'new',
    });
  }

  handleClick(e, data, target) {
    if (data.action === 'Edit') {
      this.setState({
        asset: data.row,
        modalIsOpen: true,
        modalLabel: 'Edit Asset Component',
        modalType: 'update',
      });
    } else if (data.action === 'Delete') {
      const refetchQueries = [{ query: DeviceGridQuery }];
      let cacheOptions;
      if (data.row && data.row.parentId && typeof data.row.parentId === 'string') {
        cacheOptions = [{ cachedListName: `childAssets({"parentId":"${data.row.parentId}"})` }];
      }
      const updateFn = (cache) => { cacheDeleteFromArray({ cache, deletedId: data.row.id, cacheOptions }); };
      this.setState({ refetchQueries, delete: { id: data.row.id, name: data.row.name }, updateFn });
    }
  }

  handleDeleteClose() {
    this.setState({ delete: { id: null, name: '' } });
  }

  render() {
    let loading;
    let error;
    let childAssets = [];
    if (this.props.ChildAssetsQuery) {
      loading = this.props.ChildAssetsQuery.loading;
      error = this.props.ChildAssetsQuery.error;
      childAssets = this.props.ChildAssetsQuery.childAssets;
    }

    if (loading) {
      return <p>Loading...</p>;
    } if (error) {
      return <p>Error loading asset components!</p>;
    }

    return (
      <div>
        { !childAssets || childAssets.length === 0 ? (
          <div style={{ padding: '20px' }}>
            {this.addSubAssetButton()}
            <br />
            <br />
          </div>
        ) : (
          <div style={{ paddingTop: '15px', paddingLeft: '20px', paddingRight: '20px' }}>
            <ReactTable
              showPagination={false}
              showPageSizeOptions={false}
              minRows={1}
              data={childAssets}
              columns={this.childColumns}
              className="-striped -highlight"
              defaultSorted={[{ id: 'name', asc: true }]}
            />
            {this.addSubAssetButton()}
          </div>
        )}
        <DeleteModel
          deleteMutation={DeleteAssetMutation}
          refetchQueries={this.state.refetchQueries}
          update={this.state.updateFn}
          id={this.state.delete.id}
          name={this.state.delete.name}
          onClose={this.handleDeleteClose}
        />
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel={this.state.modalLabel}
        >
          {this.state.modalType === 'new' ? (
            <ChildAssetNew
              header={this.state.modalLabel}
              parent={this.state.parent}
            />
          ) : (
            <ChildAssetUpdate asset={this.state.asset} />
          )}
        </Modal>
      </div>
    );
  }
}
export default graphql(ChildAssetsQuery, {
  name: 'ChildAssetsQuery',
  skip: (ownProps) => !ownProps.parent || !ownProps.parent.id,
  options: (ownProps) => ({ variables: { parentId: ownProps.parent.id } }),
})(ChildAssetGrid);
