import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { reduxForm, Field } from 'redux-form';
import ScalarTypeDropdown from '../../common/ScalarTypeDropdown';
import { validateNumber } from '../../common/functions';
/* import AxisDropdown from "./AxisDropdown"; */
import FormField from '../../form/FormField';
import FormSubmitButton from '../../form/FormSubmitButton';
import ColorPicker from '../../form/ColorPicker';
import ClearButton from '../../form/ClearButton';
import AttributeTypeDropdown from './attributeType/AttributeTypeDropdown';
import DataTypeDropdown from '../dataType/DataTypeDropdown';
import AttributeConfig from './AttributeConfig';
import renderCheckbox from '../../form/renderCheckbox';
import ModelTypeDropdown from '../../common/ModelTypeDropdown';

class AttributeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributeTypeId:
        this.props.initialValues && this.props.initialValues.attributeType
          ? this.props.initialValues.attributeType.id
          : null,
    };
    this.handleAttributeTypeChange = this.handleAttributeTypeChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
  }

  handleColorChange(color) {
    this.props.change('color', color);
  }

  handleAttributeTypeChange(attributeType) {
    if (attributeType !== null && attributeType.id !== null) {
      this.setState({ attributeTypeId: attributeType.id });
      // based on this attribute type
      // store the desired config vals & lift state up
      // these config vals can be used for checking form
      // and setting default values for some that need them
      // (ie. booleans might not trigger the on change event)
      this.props.setConfigVals(attributeType.fields);
    }
  }

  render() {
    const {
      handleSubmit, pristine, reset, submitting, initialValues,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div data-tip="Attribute name for display">
          <Field
            name="name"
            type="text"
            component={FormField}
            label="Attribute Name"
          />
        </div>
        <div data-tip="Data type of this attribute. Once added this cannot be changed.">
          <DataTypeDropdown
            disabled={!!initialValues}
            label="Data type"
            onDropdownChange={this.handleDataTypeChange}
            fieldName="dataType"
          />
        </div>
        <div data-tip="Attribute key for database. Once added this cannot be changed.">
          <Field
            props={{ disabled: !!initialValues }}
            name="key"
            type="text"
            component={FormField}
            label="Attribute Key"
          />
        </div>
        <ScalarTypeDropdown fieldName="rule" label="Attribute Rule" />
        <div data-tip="Legacy Id from PSL1">
          <Field
            name="legacy_id"
            type="number"
            parse={(value) => (!value ? null : Number(value))}
            component={FormField}
            label="Legacy Id"
          />
        </div>

        <div data-tip="Model this attribute is for">
          <ModelTypeDropdown label="Model Type" fieldName="modelType" />
        </div>
        <div data-tip="Type of attribute value. Optional">
          <AttributeTypeDropdown
            label="Attribute Type"
            onDropdownChange={this.handleAttributeTypeChange}
            fieldName="attributeType"
          />
        </div>
        <AttributeConfig
          initConfig={this.initConfig}
          attributeTypeId={this.state.attributeTypeId}
        />

        <Field name="browsable" label="Browsable" component={renderCheckbox} />

        <div data-tip="Value is always increasing over time">
          <Field
            name="cumulative"
            label="Cumulative"
            component={renderCheckbox}
          />
        </div>
        <div data-tip="Only admins can view this data">
          <Field
            name="adminOnly"
            label="Admin only"
            component={renderCheckbox}
          />
        </div>
        {/*  <AxisDropdown fieldName="axis" label="Y Axis" /> */}

        <label>
          Color
          <Field
            name="color"
            component={ColorPicker}
            onColorChange={this.handleColorChange}
          />
        </label>

        <Field name="units" type="text" component={FormField} label="Units" />
        <Field
          name="round"
          type="number"
          parse={(value) => (!value ? null : Number(value))}
          component={FormField}
          label="Round"
          validate={[validateNumber]}
        />
        <div data-tip="Multiply values by this factor on display">
          <Field
            name="factor"
            type="number"
            parse={(value) => (!value ? null : Number(value))}
            component={FormField}
            label="Factor"
            validate={[validateNumber]}
          />
        </div>
        <div data-tip="Add this value to data on display">
          <Field
            name="offset"
            type="number"
            parse={(value) => (!value ? null : Number(value))}
            component={FormField}
            label="Offset"
            validate={[validateNumber]}
          />
        </div>
        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

AttributeForm.defaultProps = {
  setConfigVals: () => {},
};
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.dataType) {
    errors.dataType = 'Cannot be blank';
  }
  if (!values.key) {
    errors.key = 'Cannot be blank';
  }
  if (!values.rule) {
    errors.rule = 'Cannot be blank';
  }
  if (!values.modelType) {
    errors.modelType = 'Cannot be blank';
  }
  return errors;
};
const rebuildTips = () => {
  ReactTooltip.rebuild();
};

export default reduxForm({
  validate,
  form: 'Attribute',
  onChange: rebuildTips,
})(AttributeForm);
