import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import renderCheckbox from '../../form/renderCheckbox';
import FormField from '../../form/FormField';
import FormSubmitButton from '../../form/FormSubmitButton';
import ClearButton from '../../form/ClearButton';
import AssetTypeDropdown from '../assetType/AssetTypeDropdown';
import AssetTypeConfig from '../AssetTypeConfig';
import AssetConfigDropdown from '../assetConfig/AssetConfigDropdown';

class ChildAssetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetTypeId:
        this.props.initialValues && this.props.initialValues.assetType
          ? this.props.initialValues.assetType.id
          : null,
    };
    this.handleAssetTypeChange = this.handleAssetTypeChange.bind(this);
  }

  handleAssetTypeChange(assetType) {
    this.setState({ assetTypeId: assetType.id });
    this.props.selectAssetType();
    this.props.setConfigVals(assetType.fields);
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      initialValues,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field name="name" type="text" component={FormField} label="Name" />
        <AssetTypeDropdown
          onDropdownChange={this.handleAssetTypeChange}
          label="Asset Type"
          fieldName="assetType"
        />
        <AssetTypeConfig
          assetTypeId={this.state.assetTypeId}
          formName="ChildAsset"
        />
        <br />
        <AssetConfigDropdown
          fieldName="assetConfig"
          label="Asset Configuration"
        />
        <br />
        <div data-tip="Does this asset collect oil samples">
          <Field
            name="collectsOilSamples"
            label="Collects Oil Samples"
            component={renderCheckbox}
          />
        </div>
        <br />

        <Field name="obfuscateName" type="text" component={FormField} label="Obfuscated Name" />
        <Field name="thirdPartyAssetId" type="text" component={FormField} label="Third Party ID" />

        <div>
          <FormSubmitButton pristine={pristine} submitting={submitting} />
          <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          />
        </div>
      </form>
    );
  }
}

ChildAssetForm.defaultProps = {
  initialValues: null,
  setConfigVals: () => {},
};

const validate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.assetType) {
    errors.assetType = 'Cannot be blank';
  }
  return errors;
};

ChildAssetForm = reduxForm({ validate, form: 'ChildAsset', initialValues: { } })(ChildAssetForm);

function mapStateToProps({ auth }) {
  return { auth };
}

ChildAssetForm = connect(
  mapStateToProps,
  {
    // clear assetTypeConfig when assetType is changed
    selectAssetType: () => change('ChildAsset', 'assetTypeConfig', []),
  },
)(ChildAssetForm);

export default ChildAssetForm;
