import { gql } from '@apollo/client';
import { assetAllFragment, assetRelationsFragment, childAssetsRelationsFragment } from '../fragments';

const AssetGridQuery = gql`
  query AssetGridQuery {
    editableParentAssets {
      name
      fullName
      id
      assetType {
        id
        name
      }
      assetTypeConfig {
        k
        v
      }
      parentCompanyCanView
      monitoringCompany {
        id
        name
      }
      location {
        id
        name
        company {
          id
          name
        }
      }
      thirdPartyAssetId
    }
  }
`;

const ChildAssetsQuery = gql`
  query ChildAssetsQuery($parentId: ID!) {
    childAssets(parentId: $parentId) {
      ...childAssetRelations
    }
  }
  ${childAssetsRelationsFragment}
`;

const AssetAndChildAssetGridQuery = gql`
  query AssetAndChildAssetGridQuery {
    editableParentAndChildAssets {
      name
      fullName
      id
      latitude
      longitude
      assetType {
        id
        name
      }
      assetTypeConfig {
        k
        v
      }
      parentCompanyCanView
      monitoringCompany {
        id
        name
      }
      location {
        id
        name
        company {
          id
          name
        }
      }
      parent {
        id
        name
        fullName
        location {
          id
          name
          company {
            id
            name
          }
        }
      }
      thirdPartyAssetId
    }
  }
`;

const AssetQuery = gql`
  query AssetQuery($id: ID!) {
    asset(id: $id) {
      ...assetAll
    }
  }
  ${assetAllFragment}
`;

const AssetSelectQuery = gql`
  query CompanyLocationAssetQuery {
    companies {
      id
      name
      locations {
        id
        name
        assets {
          id
          name
          fullName
        }
      }
    }
  }
`;

const AssetDropdownQuery = gql`
  query AssetDropdownQuery {
    editableParentAssets {
      name
      fullName
      id
    }
  }
`;

const AssetDropdownWithLocationQuery = gql`
  query AssetDropdownWithLocationQuery($locationId: ID!) {
    assetsByLocation(locationId: $locationId) {
      ...assetRelations
    }
  }
  ${assetRelationsFragment}
`;

export {
  AssetDropdownWithLocationQuery,
  AssetAndChildAssetGridQuery,
  AssetGridQuery,
  ChildAssetsQuery,
  AssetQuery,
  AssetSelectQuery,
  AssetDropdownQuery,
};
