import { gql } from '@apollo/client';
import { userAllFragment } from '../fragments';

const UserSelectQuery = gql`
  query CompanyUserQuery {
    companies {
      id
      name
      users {
        id
        fullName
        role
      }
    }
  }
`;

const UsersByCompanyQuery = gql`
  query UsersByCompanyQuery($companyId: ID!) {
    usersByCompany(companyId: $companyId) {
      ...userAll
    }
  }
  ${userAllFragment}
`;

const UserQuery = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      ...userAll
    }
  }
  ${userAllFragment}
`;

const UserGridQuery = gql`
  query UserGridQuery {
    editableUsers {
      ...userAll
    }
  }
  ${userAllFragment}
`;

export {
  UserSelectQuery, UsersByCompanyQuery, UserQuery, UserGridQuery,
};
