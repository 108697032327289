import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import WizardBase from '../form/WizardBase';
import AssetDropdown from '../asset/AssetDropdown';
import AssetNewWithMutation from '../asset/AssetNew';
import ChildAssetNewWithMutation from '../asset/childAsset/ChildAssetNew';

class DeviceAssetPage extends Component {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: false, parentId: null, parent: null };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleAssetChange = this.handleAssetChange.bind(this);
  }

  handleAssetChange(asset) {
    if (asset.id) {
      this.setState({ parentId: asset.id, parent: asset });
    }
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  render() {
    const {
      handleSubmit, pristine, previousPage, submitting,
    } = this.props;
    let modalContent;
    let modalType;
    if (this.state.parentId) {
      modalContent = (
        <ChildAssetNewWithMutation parent={this.state.parent} />
      );
      modalType = 'New Sub-Asset';
    } else {
      modalContent = (
        <AssetNewWithMutation
          isWizard
          showLocation={false}
          locationId={
            this.props.formState.deviceWizard
            && this.props.formState.deviceWizard.values.location
              ? this.props.formState.deviceWizard.values.location.id
              : null
          }
        />
      );
      modalType = 'New Asset';
    }

    return (
      <div>
        {this.props.formState.deviceWizard
          && this.props.formState.deviceWizard.values.location && (
            <WizardBase
              header="What asset is your device on?"
              closeModal={this.closeModal}
              modalIsOpen={this.state.modalIsOpen}
              modalContent={modalContent}
              modalType={modalType}
            >
              <form onSubmit={handleSubmit}>
                <AssetDropdown
                  fieldName="asset"
                  label="Asset"
                  onDropdownChange={this.handleAssetChange}
                  locationId={
                    this.props.formState.deviceWizard.values.location.id
                  }
                />

                <Button onClick={this.openModal}>Add New Asset</Button>
                {this.state.parentId && (
                  <div>
                    <AssetDropdown
                      label="Optional Asset Component"
                      fieldName="childAsset"
                      parentId={this.state.parentId}
                      onDropdownChange={() => {}}
                      waitForParent
                    />

                    <Button onClick={this.openModal}>Add New Asset Component</Button>
                  </div>
                )}

                <br />
                <div>
                  <Button className="previous" onClick={previousPage}>
                    Previous
                  </Button>
                  <Button type="submit" disabled={pristine || submitting}>
                    {this.props.nextButtonText}
                  </Button>
                </div>
              </form>
            </WizardBase>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  formState: state.form,
});
DeviceAssetPage.defaultProps = {
  nextButtonText: 'Submit',
};
DeviceAssetPage = reduxForm({
  form: 'deviceWizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(DeviceAssetPage);

export default connect(mapStateToProps)(DeviceAssetPage);
