import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class Landing extends Component {
  render() {
    const {
      auth, isFetchingUser, isFetchingMfa, mfaVerified,
    } = this.props;
    if (((!auth && isFetchingUser === true) || isFetchingUser === null)
     || ((!mfaVerified && isFetchingMfa === true) || isFetchingMfa === null)) {
      return <p>Loading...</p>;
    }
    if (auth) {
      if (mfaVerified) {
        console.log('redirect landing to home');
        return (
          <Redirect
            to={{
              pathname: '/homepage',
            }}
          />
        );
      }
      console.log('redirect landing to mfa');
      return (
        <Redirect
          to={{
            pathname: '/mfa',
            state: { referrer: 'Landing' },
          }}
        />
      );
    }
    console.log('redirect landing to login');
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }
}

function mapStateToProps({
  auth, isFetchingUser, isFetchingMfa, mfaVerified,
}) {
  return {
    auth, isFetchingUser, isFetchingMfa, mfaVerified,
  };
}

export default connect(mapStateToProps)(Landing);
