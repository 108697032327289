import { IS_SENDING_MFA_CODE } from '../actions/types';

export default function isSendingMfaReducer(state = null, action) {
  switch (action.type) {
    case IS_SENDING_MFA_CODE:
      return action.payload;
    default:
      return state;
  }
}
