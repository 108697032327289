import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import renderCheckbox from '../form/renderCheckbox';
import { validateNumber } from '../common/functions';
import ErrorMessage from '../common/ErrorMessage';
import FormField from '../form/FormField';
import CompanyDropdown from '../company/CompanyDropdown';
import CompanyLocationDropdown from '../company/CompanyLocationDropdown';
import AssetTypeDropdown from './assetType/AssetTypeDropdown';
import AssetTypeConfig from './AssetTypeConfig';
import AssetConfigDropdown from './assetConfig/AssetConfigDropdown';
import ChildAssetGrid from './childAsset/ChildAssetGrid';
import FormSubmitButton from '../form/FormSubmitButton';

class AssetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetTypeId:
        this.props.initialValues && this.props.initialValues.assetType
          ? this.props.initialValues.assetType.id
          : null,
      monitoringCompanyId:
        this.props.initialValues && this.props.initialValues.monitoringCompany
          ? this.props.initialValues.monitoringCompany.id
          : null,
      ownerCompanyId:
        this.props.initialValues && this.props.initialValues.ownerCompany
          ? this.props.initialValues.ownerCompany.id
          : null,
    };
    this.handleAssetTypeChange = this.handleAssetTypeChange.bind(this);
    this.handleMonitoringCompanySelect = this.handleMonitoringCompanySelect.bind(this);
    this.handleOwnerCompanySelect = this.handleOwnerCompanySelect.bind(this);
    this.handleClickSaveAndClose = this.handleClickSaveAndClose.bind(this);
    this.handleClickAddComponents = this.handleClickAddComponents.bind(this);
  }

  handleAssetTypeChange(assetType) {
    this.setState({ assetTypeId: assetType.id });
    this.props.selectAssetType();
    this.props.setConfigVals(assetType.fields);
  }

  handleMonitoringCompanySelect(monitoringCompany) {
    if (monitoringCompany.id !== this.state.ownerCompanyId) {
      this.setState({ monitoringCompanyId: monitoringCompany.id });
    } else {
      // error if monitoringCompany is the same as owner company
      this.props.clearMonitoringCompany();
      this.onError('Monitoring Company and Owner Company cannot be the same');
    }
  }

  handleOwnerCompanySelect(ownerCompany) {
    if (ownerCompany.id !== this.state.monitoringCompanyId) {
      this.setState({ ownerCompanyId: ownerCompany.id });
      this.props.selectCompany();
    } else {
      // error if monitoringCompany is the same as owner company
      this.props.clearMonitoringCompany();
      this.onError('Monitoring Company and Owner Company cannot be the same');
    }
  }

  onError(error) {
    ErrorMessage({
      msg: error,
    });
  }

  handleClickSaveAndClose() {
    this.props.setAddingAssetComponent(false, this.props.handleSubmit);
  }

  handleClickAddComponents() {
    this.props.setAddingAssetComponent(true, this.props.handleSubmit);
  }

  render() {
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      initialValues,
    } = this.props;
    let isUpdate = false;
    if (initialValues && initialValues.id) {
      isUpdate = true;
    }
    return (
      <form>
        {!this.props.showLocation ? null : (
          <CompanyLocationDropdown
            companyFieldName={initialValues ? 'location.company' : 'company'}
            locationFieldName="location"
            companyId={
              initialValues
              && initialValues.location
              && initialValues.location.company
                ? initialValues.location.company.id
                : null
            }
            selectCompany={this.handleOwnerCompanySelect}
            selectLocation={() => {}}
          />
        )}

        <Field name="name" type="text" component={FormField} label="Name" />
        <AssetTypeDropdown
          onDropdownChange={this.handleAssetTypeChange}
          label="Asset Type"
          fieldName="assetType"
        />
        <AssetTypeConfig assetTypeId={this.state.assetTypeId} />
        <br />
        <AssetConfigDropdown
          fieldName="assetConfig"
          label="Asset Configuration"
        />

        { ((this.props.auth && this.props.auth.isSuperAdmin)
          || (this.props.auth && this.props.auth.company && this.props.auth.company.isMonitoringCompany)) && (
          <div>
            <div data-tip="Use if the asset is monitored by a company other than the owner of the asset">
              <CompanyDropdown fieldName="monitoringCompany" label="Monitoring Company" onDropdownChange={this.handleMonitoringCompanySelect} />
            </div>
            <div data-tip="Can the asset's owner view this asset? (use if being monitored by another company)">
              <Field
                name="parentCompanyCanView"
                label="Asset Owner Company Can View"
                component={renderCheckbox}
              />
            </div>
          </div>
        )}
        <br />
        <div data-tip="Does this asset collect oil samples">
          <Field
            name="collectsOilSamples"
            label="Collects Oil Samples"
            component={renderCheckbox}
          />
        </div>
        <br />
        <div>
          <Field
            name="latitude"
            type="number"
            parse={(value) => (!value ? null : Number(value))}
            component={FormField}
            label="Latitude"
            validate={[validateNumber]}
          />
          <Field
            name="longitude"
            type="number"
            parse={(value) => (!value ? null : Number(value))}
            component={FormField}
            label="Longitude"
            validate={[validateNumber]}
          />
        </div>
        <Field name="obfuscateName" type="text" component={FormField} label="Obfuscated Name" />
        <div data-tip="Asset ID used in external APIs">
          <Field name="thirdPartyAssetId" type="text" component={FormField} label="Third Party ID" />
        </div>

        { (isUpdate) && (
        <div>
          <label>Asset Components</label>
          <ChildAssetGrid
            parent={initialValues}
          />
          <br />
        </div>
        )}

        <div>
          { (!isUpdate && !this.props.isWizard) && (
            <div>
              <FormSubmitButton
                pristine={pristine}
                submitting={submitting}
                onClick={this.handleClickAddComponents}
                buttonText="Add Components"
              />
              <FormSubmitButton
                pristine={pristine}
                submitting={submitting}
                onClick={this.handleClickSaveAndClose}
                buttonText="Save & Close"
              />
            </div>
          )}
          { (isUpdate || this.props.isWizard) && (
          <FormSubmitButton
            pristine={pristine}
            submitting={submitting}
            onClick={handleSubmit}
          />
          )}
          {/* <ClearButton
            pristine={pristine}
            submitting={submitting}
            reset={reset}
          /> */}
        </div>
      </form>

    );
  }
}

AssetForm.defaultProps = {
  initialValues: null,
  showLocation: true,
  setConfigVals: () => {},
  isWizard: false,
};

const validate = (values, props) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Cannot be blank';
  }
  if (!values.assetType) {
    errors.assetType = 'Cannot be blank';
  }
  if ((props.showLocation) && (!values.location || (values.location && !values.location.id))) {
    errors.location = 'Company and Location are required';
  }
  return errors;
};

AssetForm = reduxForm({ validate, form: 'Asset', initialValues: { parentCompanyCanView: true } })(AssetForm);

function mapStateToProps({ auth }) {
  return { auth };
}

AssetForm = connect(
  mapStateToProps,
  {
    selectCompany: () => change('Asset', 'location', {
      id: '',
      name: '',
    }),
    selectLocation: () => {},
    // clear assetTypeConfig when assetType is changed
    selectAssetType: () => change('Asset', 'assetTypeConfig', []),
    clearMonitoringCompany: () => change('Asset', 'monitoringCompany', {
      id: '',
      name: '',
    }),
  },
)(AssetForm);

export default AssetForm;
