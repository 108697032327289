import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import ChildAssetForm from './ChildAssetForm';
import FormBase from '../../form/FormBase';
import handleMutation from '../../../graphql/functions/handleMutation';
import handleAssetTypeMutation from '../../../graphql/functions/handleAssetTypeMutation';

import { CreateAssetMutation } from '../../../graphql/mutations/AssetMutations';
import { CreateAssetTypeMutation } from '../../../graphql/mutations/AssetTypeMutations';
import cacheAddToArray from '../../../graphql/functions/cacheAddToArray';
import { childAssetsRelationsFragment } from '../../../graphql/fragments';

class ChildAssetNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      assetTypeConfigVals: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAsset = this.handleSubmitAsset.bind(this);
    this.setConfigVals = this.setConfigVals.bind(this);
  }

  setConfigVals(vals) {
    this.setState({ assetTypeConfigVals: vals });
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // this is never set, so this code never runs to initialize the configs, they are just "empty"
      const assetTypeConfigs = this.state.assetTypeConfigVals;
      for (let i = 0; i < assetTypeConfigs.length; i++) {
        // if value not set, set to null or false if boolean
        if (!values.assetTypeConfig[i]) {
          if (assetTypeConfigs[i].rule === 'Boolean') {
            // if this boolean has not been set, automatically set it to false
            values.assetTypeConfig[i] = { k: assetTypeConfigs[i].key, v: false };
          } else {
            values.assetTypeConfig[i] = { k: assetTypeConfigs[i].key, v: null };
          }
        }
      }
      delete values.company;
      delete values.fullName;
      delete values.uploadCSV;
      delete values.location;

      values.parent = this.props.parent.id;

      if (
        typeof values.assetType
        && values.assetType !== null
        && typeof values.assetType === 'object'
      ) {
        // if new AssetType, save this AssetType
        if (values.assetType.assetTypeName) {
          handleAssetTypeMutation({
            mutation: this.props.createAssetTypeMutation,
            values,
            handleSubmitAsset: () => {
              this.handleSubmitAsset(values);
            },
          });
        } else if (values.assetType.id) {
          // store AssetType Id as assetType
          values.assetType = values.assetType.id;
          this.handleSubmitAsset(values);
        }
      } else {
        this.handleSubmitAsset(values);
      }
    }
  }

  handleSubmitAsset(values) {
    const refetchQueries = [];

    const cacheOptions = [{
      cachedListName: `childAssets({"parentId":"${this.props.parent.id}"})`,
      fragment: childAssetsRelationsFragment,
      fragmentName: 'childAssetRelations',
    }];

    const updateFn = (cache, { data }) => {
      cacheAddToArray({
        cache,
        data,
        mutationString: 'createAsset',
        cacheOptions,
      });
    };
    handleMutation({
      mutate: this.props.createAssetMutation,
      variables: { asset: values },
      refetchQueries,
      onSuccess: this.props.onSuccess,
      update: updateFn,
    });
  }

  render() {
    return (
      <FormBase header={this.props.header}>
        <ChildAssetForm
          onSubmit={this.handleSubmit}
          setConfigVals={this.setConfigVals}
        />
      </FormBase>
    );
  }
}
ChildAssetNew.defaultProps = {
  parent: '',
  header: 'Create Asset Component',
};
const ChildAssetNewWithMutation = compose(
  graphql(CreateAssetMutation, { name: 'createAssetMutation' }),
  graphql(CreateAssetTypeMutation, { name: 'createAssetTypeMutation' }),
)(ChildAssetNew);

export default ChildAssetNewWithMutation;
