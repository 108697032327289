import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';

import AssetForm from './AssetForm';
import FormBase from '../form/FormBase';
import handleMutation from '../../graphql/functions/handleMutation';
import handleAssetTypeMutation from '../../graphql/functions/handleAssetTypeMutation';
import {
  AssetDropdownWithLocationQuery,
} from '../../graphql/queries/AssetQueries';

import { CreateAssetMutation } from '../../graphql/mutations/AssetMutations';
import { CreateAssetTypeMutation } from '../../graphql/mutations/AssetTypeMutations';
import cacheAddToArray from '../../graphql/functions/cacheAddToArray';
import { assetAllFragment, assetRelationsFragment } from '../../graphql/fragments';
import Modal from '../common/Modal';
import AssetNewComponents from './AssetNewComponents';

class AssetNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      assetTypeConfigVals: {},
      createdAsset: null,
      addAssetComponentModalIsOpen: false,
      addingComponents: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAsset = this.handleSubmitAsset.bind(this);
    this.setConfigVals = this.setConfigVals.bind(this);
    this.openAddAssetComponentModal = this.openAddAssetComponentModal.bind(this);
    this.closeAddAssetComponentModal = this.closeAddAssetComponentModal.bind(this);
    this.onSuccessOfSavePreAddComponents = this.onSuccessOfSavePreAddComponents.bind(this);
    this.setAddingAssetComponent = this.setAddingAssetComponent.bind(this);
  }

  openAddAssetComponentModal() {
    this.setState({ addAssetComponentModalIsOpen: true });
  }

  closeAddAssetComponentModal() {
    this.setState({ addAssetComponentModalIsOpen: false });
    this.props.closeNewAssetModal();
  }

  setAddingAssetComponent(addingComponents, callbackFn) {
    this.setState({ addingComponents }, () => {
      callbackFn();
    });
  }

  onSuccessOfSavePreAddComponents(response) {
    this.setState({ createdAsset: response.data.createAsset });
    this.openAddAssetComponentModal();
  }

  setConfigVals(vals) {
    this.setState({ assetTypeConfigVals: vals });
  }

  handleSubmit(values) {
    if (typeof values === 'object') {
      // this is never set, so this code never runs to initialize the configs, they are just "empty"
      const assetTypeConfigs = this.state.assetTypeConfigVals;
      for (let i = 0; i < assetTypeConfigs.length; i++) {
        // if value not set, set to null or false if boolean
        if (!values.assetTypeConfig[i]) {
          if (assetTypeConfigs[i].rule === 'Boolean') {
            // if this boolean has not been set, automatically set it to false
            values.assetTypeConfig[i] = { k: assetTypeConfigs[i].key, v: false };
          } else {
            values.assetTypeConfig[i] = { k: assetTypeConfigs[i].key, v: null };
          }
        }
      }
      delete values.company;
      delete values.fullName;
      delete values.uploadCSV;
      if (
        typeof values.location === 'object'
        && values.location !== null
        && values.location.id
      ) {
        values.location = values.location.id;
      } else if (
        typeof this.props.location === 'object'
        && this.props.location !== null
        && this.props.location.id
      ) {
        values.location = this.props.location.id;
      } else if (this.props.locationId) {
        values.location = this.props.locationId;
      }
      if (
        typeof values.assetType
        && values.assetType !== null
        && typeof values.assetType === 'object'
      ) {
        // if new AssetType, save this AssetType
        if (values.assetType.assetTypeName) {
          handleAssetTypeMutation({
            mutation: this.props.createAssetTypeMutation,
            values,
            handleSubmitAsset: () => {
              this.handleSubmitAsset(values);
            },
          });
        } else if (values.assetType.id) {
          // store AssetType Id as assetType
          values.assetType = values.assetType.id;
          this.handleSubmitAsset(values);
        }
      } else {
        this.handleSubmitAsset(values);
      }
    }
  }

  handleSubmitAsset(values) {
    const refetchQueries = [];

    if (this.props.locationId) {
      refetchQueries.push({
        query: AssetDropdownWithLocationQuery,
        variables: { locationId: this.props.locationId },
      });
    }

    const cacheOptions = [{ cachedListName: 'editableParentAssets', fragment: assetAllFragment, fragmentName: 'assetAll' }];
    if (values.location && typeof values.location === 'string') {
      cacheOptions.push({
        cachedListName: `assetsByLocation({"locationId":"${values.location}"})`,
        fragment: assetRelationsFragment,
        fragmentName: 'assetRelations',
      });
    }

    const updateFn = (cache, { data }) => {
      cacheAddToArray({
        cache,
        data,
        mutationString: 'createAsset',
        cacheOptions,
      });
    };
    let successFn;
    if (this.state.addingComponents === true) {
      successFn = this.onSuccessOfSavePreAddComponents;
    } else {
      successFn = this.props.onSuccess;
    }
    handleMutation({
      mutate: this.props.createAssetMutation,
      variables: { asset: values },
      refetchQueries,
      onSuccess: successFn,
      update: updateFn,
    });
  }

  render() {
    return (
      <div>
        <FormBase header={this.props.header}>
          <AssetForm
            isWizard={this.props.isWizard}
            onSubmit={this.handleSubmit}
            setAddingAssetComponent={this.setAddingAssetComponent}
            showLocation={this.props.showLocation}
            setConfigVals={this.setConfigVals}
          />
        </FormBase>
        <Modal
          onClose={this.closeAddAssetComponentModal}
          modalIsOpen={this.state.addAssetComponentModalIsOpen}
          contentLabel="Add Asset Component"
        >

          <AssetNewComponents createdAsset={this.state.createdAsset} />

        </Modal>
      </div>

    );
  }
}
AssetNew.defaultProps = {
  header: 'Create Asset',
  showLocation: true,
  isWizard: false,
};
const AssetNewWithMutation = compose(
  graphql(CreateAssetMutation, { name: 'createAssetMutation' }),
  graphql(CreateAssetTypeMutation, { name: 'createAssetTypeMutation' }),
)(AssetNew);

export default AssetNewWithMutation;
