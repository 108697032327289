import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { reduxForm, Field } from 'redux-form';
import CSVFileInput from '../form/CSVFileInput';
import FormSubmitButton from '../form/FormSubmitButton';
import ClearButton from '../form/ClearButton';

class SimpleImportForm extends Component {
  render() {
    console.log(this.props);
    const {
      handleSubmit, pristine, reset, submitting,
    } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Field
            name="uploadedData"
            onChange={this.props.handleFileLoaded}
            onError={this.props.handleFileError}
            component={CSVFileInput}
          />
          <br />

          <div>
            <FormSubmitButton pristine={pristine} submitting={submitting} />
            <ClearButton
              pristine={pristine}
              submitting={submitting}
              reset={reset}
            />
          </div>
        </form>
        <Typography variant="h5">Instructions</Typography>
        <p>
          Upload csv data to update or create new assets with associated
          devices.
          <br />
          Fields to include are:
          <br />
          <strong>Required:</strong>
          &nbsp;&apos;company_name&apos;, &apos;location_name&apos;,
          &apos;asset_name&apos;
          <br />
          <strong>Optional:</strong>
          &nbsp;&apos;asset_type&apos;- defaults to Wind Turbine, &apos;component_name&apos;,
          &apos;component_type&apos;- defaults to Gearbox, &apos;asset_config&apos;, &apos;component_config&apos;,
          &apos;asset_thirdPartyId&apos;, &apos;component_thirdPartyId&apos;, &apos;dm_serial&apos;,
          &apos;qw_serial&apos;, &apos;vm_serial&apos;, &apos;ap2200_serial&apos;, &apos;vib_serial&apos;, &apos;vib_name&apos;,
          &apos;dm_deviceGroupNames&apos;, &apos;vib_deviceGroupNames&apos;, &apos;qw_deviceGroupNames&apos;,
          &apos;vm_deviceGroupNames&apos;, &apos;ap2200_deviceGroupNames&apos;
        </p>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  return errors;
};

export default reduxForm({
  validate,
  form: 'SimpleImport',
})(SimpleImportForm);
