import { gql } from '@apollo/client';

export const ap2200FirmwareFragment = gql`
  fragment ap2200Firmware on Ap2200Firmware {
    id
    isDefaultLatest
    majorVersion
    version
    fileExists
  }
`;

export const attributeAllFragment = gql`
  fragment attributeAll on Attribute {
    id
    name
    key
    browsable
    units
    color
    factor
    offset
    round
    rule
    legacy_id
    modelType
    cumulative
    adminOnly
    dataType {
      id
      name
    }
    attributeType {
      id
      name
    }
    config {
      k
      v
    }
  }
`;

export const eventFragment = gql`
  fragment event on Event {
    id
    name
    dateAdded
    timestamp
    public
  }
`;

export const userBasicFragment = gql`
  fragment userBasic on User {
    id
    fullName
    firstName
    lastName
    email
    role
    latestVisit
  }
`;

export const userAllFragment = gql`
  fragment userAll on User {
    id
    fullName
    firstName
    lastName
    email
    role
    receiveEmail
    receiveSMS
    missedCommunicationAlerts
    latestVisit
    kinematicViscosityAssumedValue
    kinematicViscosity
    displayTempsF
    accountOnlyView
    accountOnlyNotifications
    obfuscate
    mismatchedDeviceAlerts
    company {
      id
      name
      isMonitoringCompany
    }
  }
`;

export const accountManagerAllFragment = gql`
  fragment accountManagerAll on AccountManager {
    user {
      ...userAll
    }
    company {
      id
      name
    }
    rank
  }
  ${userAllFragment}
`;

export const dataTypeAllFragment = gql`
  fragment dataTypeAll on DataType {
    id
    name
    key
    attributes {
      id
      name
    }
  }
`;

export const tableFragment = gql`
  fragment table on Table {
    id
    name
    title
    raw
    columns {
      name
      attribute {
        id
        name
        key
      }
      type
      addPercent
    }
    mostRecent
    dateColumn
  }
`;

export const modelDashboardFragment = gql`
  fragment modelDashboardAll on ModelDashboard {
    id
    name
    charts {
      id
      name
    }
    tables {
      id
      name
    }
    displays
  }
`;

export const attConfigFragment = gql`
  fragment attConfig on AttributeConfiguration {
    importField
    queryFunction
    queryParams
    displayFactor
    displayOffset
    primaryAttribute
    ignore
    min
    max
    attribute {
      name
      id
      dataType {
        id
      }
    }
    category
  }
`;

// #region Oil Sample Types
export const oilSampleTypeFragment = gql`
  fragment oilSampleType on OilSampleType {
    id
    name
    metadataFields {
      ...attConfig
    }
    fields {
      ...attConfig
    }
  }
  ${attConfigFragment}
`;

// #endregion

// #region Installer Config
export const installerConfigFieldFragment = gql`
  fragment installerConfigField on InstallerConfigField {
    id
    name
    deviceType {
      id
      name
    }
    serialNumberType
    serialNumberPrefix
    inputs {
      label
      fieldKey
      inputType
      informationalImageUrl
      options {
        name
        val
      }
    }
  }
`;

export const installerConfigFragment = gql`
  fragment installerConfig on InstallerConfig {
    id
    default
    name
    formFields {
      default
      installerConfigField {
        ...installerConfigField
      }
    }
  }
  ${installerConfigFieldFragment}
`;
// #endregion

export const locationAllFragment = gql`
  fragment locationAll on Location {
    name
    obfuscateName
    apiKey
    id
    company {
      id
      name
    }
    latitude
    longitude
    modelDashboard {
      ...modelDashboardAll
    }
    installerConfig {
      ...installerConfig
    }
    servicePlan
    serviceExpirationDate
  }
  ${modelDashboardFragment}
  ${installerConfigFragment}
`;

export const assetRelationsFragment = gql`
  fragment assetRelations on Asset {
    name
    fullName
    id
    parent {
      id
      name
      fullName
      location {
        id
        name
        company {
          id
          name
        }
      }
    }
    location {
      id
      name
      company {
        id
        name
      }
    }
  }
`;

export const childAssetsRelationsFragment = gql`
  fragment childAssetRelations on Asset { 
    id
    fullName
    name
    assetType {
      id
      name
    }
    parentCompanyCanView
    monitoringCompany {
      id
      name
    }
    parent {
      id
      name
      fullName
      location {
        id
        name
        company {
          id
          name
        }
      }
    }
    ...assetRelations
  }
  ${assetRelationsFragment}
`;

export const assetConfigOnlyFragment = gql`
  fragment assetConfiguration on AssetConfig {
    id
    name
    attConfigs {
      ...attConfig
    }
    modelDashboard {
      ...modelDashboardAll
    }
  }
  ${attConfigFragment}
  ${modelDashboardFragment}
`;

export const assetTypeAllFragment = gql`
  fragment assetTypeAll on AssetType {
    id
    name
    fields {
      key
      description
      name
      required
      rule
    }
    assetConfig {
      ...assetConfiguration
    }
  }
  ${assetConfigOnlyFragment}
`;

export const assetAllFragment = gql`
  fragment assetAll on Asset {
    name
    obfuscateName
    fullName
    id
    assetType {
      ...assetTypeAll
    }
    assetTypeConfig {
      k
      v
    }
    latitude
    longitude
    collectsOilSamples
    parentCompanyCanView
    monitoringCompany {
      id
      name
    }
    childAssets {
      name
      fullName
      id
      assetType {
        ...assetTypeAll
      }
      assetConfig {
        ...assetConfiguration
      }
      parent {
        id
        name
        fullName
        location {
          id
          name
          company {
            id
            name
          }
        }
      }
    }
    parent {
      id
      name
      fullName
      location {
        id
        name
        company {
          id
          name
        }
      }
    }
    location {
      id
      name
      company {
        id
        name
      }
    }
    assetConfig {
      ...assetConfiguration
    }
    thirdPartyAssetId
  }
  ${assetConfigOnlyFragment}
  ${assetTypeAllFragment}
`;

// #region Device
export const deviceConfigOnlyFragment = gql`
  fragment deviceConfiguration on DeviceConfig {
    id
    name
    indicatorWidgets {
      widgetType
      widgetId
    }
    processingFunctions
    attConfigs {
      ...attConfig
    }
    modelDashboard {
      ...modelDashboardAll
    }
  }
  ${attConfigFragment}
  ${modelDashboardFragment}
`;

export const deviceTypeFragment = gql`
  fragment deviceType on DeviceType {
    id
    name
    key
    importKeys
    fields {
      key
      description
      name
      required
      rule
    }
    deviceConfig {
      ...deviceConfiguration
    }
  }
  ${deviceConfigOnlyFragment}
`;

export const deviceAssetLocationCompanyFragment = gql`
  fragment deviceAssetLocationCompany on Device {
    id
    name
    asset {
      id
      name
      fullName
      parent {
        id
        name
        fullName
        location {
          id
          name
          company {
            id
            name
          }
        }
      }
      location {
        id
        name
        company {
          id
          name
        }
      }
    }
  }
`;

export const deviceBasicsFragment = gql`
  fragment deviceBasics on Device {
    id
    name
    serial
    firmware
    installLocation
    installDate
    secondsBetweenPts
    importId
    isBackfilling
    numContinuousPts
    active
    display
    latestIp
    legacy_id
    legacy_config
    legacy_serial
    asset {
      name
      fullName
      parent {
        id
        name
        fullName
        location {
          id
          name
          company {
            id
            name
          }
        }
      }
      id
      location {
        id
        name
        company {
          id
          name
        }
      }
    }
    owner {
      name
      id
    }
    deviceType {
      name
      id
    }
    deviceConfig {
      id
      name
    }
    deviceTypeConfig {
      k
      v
    }
  }
`;

export const deviceAllFragment = gql`
  fragment deviceAll on Device {
    id
    name
    serial
    firmware
    installLocation
    installDate
    secondsBetweenPts
    importId
    latestIp
    isBackfilling
    numContinuousPts
    active
    display
    legacy_id
    legacy_config
    legacy_serial
    additionalOptions
    asset {
      name
      fullName
      id
      parent {
        name
        fullName
        id
        location {
          id
          name
          company {
            id
            name
          }
        }
      }
      location {
        id
        name
        company {
          id
          name
        }
      }
    }
    owner {
      name
      id
    }
    deviceType {
      name
      id
    }
    deviceConfig {
      ...deviceConfiguration
    }
    deviceTypeConfig {
      k
      v
    }
    desiredFirmware {
      ...ap2200Firmware
    }
    forceFirmwareUpdate
    dataForwardUrl
    dataForwardOnly
    sendAp2200LogFile
  }
  ${ap2200FirmwareFragment}
  ${deviceConfigOnlyFragment}
`;

export const deviceGridFragment = gql`
  fragment deviceGrid on Device {
    ...deviceBasics
    latestConnection
    dataForwardUrl
    dataForwardOnly
  }
  ${deviceBasicsFragment}
`;

export const ap2200GridFragment = gql`
  fragment ap2200Grid on Device {
    ...deviceBasics
    latestConnection
    desiredFirmware {
      ...ap2200Firmware
    }
    forceFirmwareUpdate
    sendAp2200LogFile
  }
  ${deviceBasicsFragment}
  ${ap2200FirmwareFragment}
`;
// endregion Device

// #region Company
export const companyAllFragment = gql`
  fragment companyAll on Company {
    id
    obfuscateName
    name
    id
    isMonitoringCompany
    displayRul
    modelDashboard {
      ...modelDashboardAll
    }
  }
  ${modelDashboardFragment}
`;

export const companyAllWithResponsibleUsersFragment = gql`
  fragment companyAllWithResponsibleUsers on Company {
    ...companyAll
    accountManagers {
      id
      user {
        id
        email
        fullName
      }
      rank
    }
  }
${companyAllFragment}
`;
export const companyLocationFragment = gql`
  fragment companyLocation on Company {
    id
    name
    locations {
      id
      name
    }
  }
`;

export const companyLocationAssetFragment = gql`
  fragment companyLocationAsset on Company {
    id
    name
    locations {
      id
      name
      assets {
        name
        id
      }
    }
  }
`;
// endregion Company

// #region Chart
export const chartFragment = gql`
  fragment chart on Chart {
    id
    name
    title
    chartAttributes {
      attribute {
        name
        id
      }
      axis
      visible
      valueType
    }
    plotType
    defaultSort
    default
    raw
    additionalOptions
  }
`;
// #endregion

// #region DeviceGroup
export const deviceGroupFragment = gql`
  fragment deviceGroup on DeviceGroup {
    id
    name
    deviceType {
      id
      name
    }
  }
`;
// #endregion
