import React from 'react';
import { Field } from 'redux-form';
import ModelDashboardDropdown from '../modelDashboard/ModelDashboardDropdown';
import renderCheckbox from '../form/renderCheckbox';

export default [
  {
    id: 'modelDashboard',
    name: 'Model Dashboard',
    field: (
      <ModelDashboardDropdown
        fieldName="modelDashboard"
        label="Model Dashboard"
        onDropdownChange={() => {}}
      />
    ),
  },
  {
    id: 'displayRul',
    name: 'Display RUL',
    field: (
      <Field
        name="displayRul"
        label="Display RUL"
        component={renderCheckbox}
      />
    ),
  },
];
