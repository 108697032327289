import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import GridBase from '../common/GridBase';
import Modal from '../common/Modal';
import ViewJson from '../common/ViewJson';
import { DataLogGridQuery } from '../../graphql/queries/DataLogQueries';
import displayTimestamp from '../common/displayTimestamp';
import timestampSort from '../common/timestampSort';
import RenderDatePicker from '../form/renderDatePicker';

function CellButton(row, handleClick) {
  return (
    <Button
      title="View Full JSON"
      onClick={() => handleClick(row)}
    >
      <i className="material-icons">search</i>
    </Button>
  );
}

class DataLogGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      t1: Math.floor((new Date().getTime() - (2 * 60 * 60 * 1000)) / 1000),
      t2: Math.floor(new Date().getTime() / 1000),
      modalIsOpen: false,
      dataLog: { createdAt: null, ipAddress: '', dataJson: '' },
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  columns = [
    {
      Header: 'Time',
      maxWidth: 220,
      id: 'createdAt',
      accessor: (d) => displayTimestamp(d, 'createdAt'),
      sortMethod: timestampSort,
      filterable: true,
    },
    {
      Header: 'IP',
      maxWidth: 150,
      accessor: 'ipAddress',
      filterable: true,
    },
    {
      Header: 'Data',
      accessor: 'dataJson',
      filterable: true,
    },
    {
      Header: '',
      maxWidth: 100,
      sortable: false,
      Cell: ({ row }) => CellButton(row, this.handleClick),
    },
  ];

  handleChange(event) {
    this.setState({ searchTerm: event.target.value });
  }

  handleStartChange(date) {
    this.setState({ t1: Math.floor(date / 1000) });
  }

  handleEndChange(date) {
    this.setState({ t2: Math.floor(date / 1000) });
  }

  handleSubmit(event, refetch) {
    event.preventDefault();
    refetch({
      searchTerm: this.state.searchTerm ? this.state.searchTerm : '',
      t1: this.state.t1 ? this.state.t1 : 0,
      t2: this.state.t2 ? this.state.t2 : Math.floor(new Date().getTime() / 1000),
    });
  }

  handleClick(row) {
    console.log('row', row);
    this.setState({ dataLog: row });
    this.openModal();
  }

  renderFilterBar(loading, refetch) {
    return (
      <form onSubmit={(e) => this.handleSubmit(e, refetch)}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={6}>
            <input
              type="text"
              name="search"
              value={this.state.searchTerm}
              onChange={this.handleChange}
              placeholder="Search by AP2200 sn, fw, name or IP Address"
              title="Search by AP2200 sn, fw, name or IP Address"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <RenderDatePicker
              name="startDate"
              placeholder="Start Date"
              input={{ value: this.state.t1, onChange: (d) => { this.handleStartChange(d); } }}
              meta={{}}
              label="startDate"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <RenderDatePicker
              name="endDate"
              placeholder="End Date"
              input={{ value: this.state.t2, onChange: (d) => { this.handleEndChange(d); } }}
              meta={{}}
              label="endDate"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  render() {
    const {
      DataLogGridQuery: {
        loading, error, dataLogs, refetch,
      },
    } = this.props;
    const sorting = [{ id: 'createdAt', desc: true }];
    if (loading) {
      return (
        <div>
          <div>{this.renderFilterBar(loading, refetch)}</div>
          <p>Loading data logs...</p>
        </div>
      );
    } if (error) {
      return <p>Error loading data logs!</p>;
    }
    return (
      <div>
        <div>{this.renderFilterBar(loading, refetch)}</div>
        <GridBase
          dataVar={dataLogs}
          columns={this.columns}
          sorting={sorting}
        />
        <Modal
          onClose={this.closeModal}
          modalIsOpen={this.state.modalIsOpen}
          contentLabel="Data Log"
        >
          <ViewJson
            header={`${this.state.dataLog.createdAt} - ${this.state.dataLog.ipAddress}`}
            data={this.state.dataLog.dataJson}
          />
        </Modal>
      </div>
    );
  }
}

export default graphql(DataLogGridQuery, {
  name: 'DataLogGridQuery',
  options: () => ({
    variables: {
      searchTerm: '',
      t1: Math.floor((new Date().getTime() - (2 * 60 * 60 * 1000)) / 1000),
      t2: Math.floor(new Date().getTime() / 1000),
    },
  }),
})(DataLogGrid);
